import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

import { Button } from "react-bootstrap";

import "./style.css";
//import "./radio.css";
import Desktop from "./desktop/PrivacyScreen.desktop";
import Mobile from "./mobile/PrivacyScreen.mobile";
const url = "https://api.securepaymentpcs.com";
//const url = "http://localhost:5555";
const options = {
  style: {
    base: {
      color: "white",
      fontSize: "16px",
      "::placeholder": {
        color: "#BBE0FA",
      },
    },
    invalid: {
      color: "#F37676",
    },
  },
};
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      cardNumber: "",
      cardName: "",
      loading: true,
      isError: false,
    };
  }

  render() {
    let { isSubmitting } = this.state;
    return (
      <div className="body">
        {window.innerWidth > 600 ? (
          <Desktop isSubmitting={isSubmitting} history={this.props.history} />
        ) : (
          <Mobile isSubmitting={isSubmitting} history={this.props.history} />
        )}
      </div>
    );
  }
}

export default Home;
