import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./screens/Main.js";
import Privacy from "./screens/Privacy.js";

import reportWebVitals from "./reportWebVitals";

//import { Router, globalHistory } from "@reach/router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: 0,
    };
  }

  componentDidMount() {}

  render() {
    const isMobile = window.innerWidth <= 920 && true;
    //const MainScreen = isMobile ? MainScreenMobile : MainScreenDesktop;
    return (
      <Router>
        <div>
          <Switch>
            <Route component={Privacy} path="/privacy" />
            <Route component={Main} path="/:id" />
            <Route path="*">
              <div className="body info">
                <div className="content info-content">
                  <div className="info-text">404</div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
