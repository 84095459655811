import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.desktop.css";
import { Link } from "react-router-dom";

import arrowSvg from "../../assets/arrow-left.svg";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.change = this.change.bind(this);
  }

  change(event) {
    let { state } = this;
    state[event.target.name] = event.target.value;

    this.setState(state);
  }

  render() {
    const { radios } = this.state;
    const {
      isSubmitting,
      submitReady,
      navIndex,
      to,
      from,
      locationChange,
      handleSubmit,
    } = this.props;
    return (
      <div className="content privacy">
        <div className="nav-header">
          <Link onClick={() => this.props.history.goBack()}>
            <img src={arrowSvg} />
          </Link>
          <div className="privacy-container-title">Privacy Policy</div>
          <div></div>
        </div>
        <div className="nav-content">
          <div className="privacy-muted">Last updated: May 01, 2023</div>

          <div className="privacy-content">
            <br />
            <div className="privacy-text">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </div>
            <br />
            <div className="privacy-text">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </div>
            <br />
            <div className="privacy-title">Interpretation and Definitions</div>
            <div className="privacy-text">==============================</div>
            <br />
            <div className="privacy-title">Interpretation</div>
            <div className="privacy-text">--------------</div>
            <br />
            <div className="privacy-text">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </div>
            <br />
            <div className="privacy-title">Definitions</div>
            <div className="privacy-text">-----------</div>
            <br />
            <div className="privacy-text">
              For the purposes of this Privacy Policy:
            </div>
            <br />
            <div className="privacy-text">
              <ul>
                <li>
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </li>

                <li>
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our" in this Agreement) refers to Colibri Auto Transport Inc,
                  3411 Silverside Rd Baynard Building, Wilmington, DE 19810.
                </li>

                <li>
                  Cookies are small files that are placed on Your computer,
                  mobile device or any other device by a website, containing the
                  details of Your browsing history on that website among its
                  many uses.
                </li>

                <li>Country refers to: Delaware, United States</li>

                <li>
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </li>

                <li>
                  Personal Data is any information that relates to an identified
                  or identifiable individual.
                </li>

                <li>Service refers to the Website.</li>

                <li>
                  Service Provider means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used.
                </li>

                <li>
                  Usage Data refers to data collected automatically, either
                  generated by the use of the Service or from the Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </li>

                <li>
                  Website refers but is not limited to Payment Link, Customer
                  Arrangements and Online Booking Link.
                </li>

                <li>
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </li>
              </ul>
            </div>
            <br />
            <div className="privacy-title">
              Collecting and Using Your Personal Data{" "}
            </div>
            <div className="privacy-text">
              =======================================
            </div>
            <br />
            <div className="privacy-title">Types of Data Collected</div>
            <div className="privacy-text">-----------------------</div>
            <br />
            <div className="privacy-title">Personal Data</div>
            <div className="privacy-text">~~~~~~~~~~~~~</div>
            <br />
            <div className="privacy-text">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </div>
            <br />
            <div className="privacy-text">
              <ul>
                <li>Email address</li>

                <li>First name and last name</li>

                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
              </ul>
            </div>
            <br />
            <div className="privacy-title">Use of Your Personal Data</div>
            <div className="privacy-text">-------------------------</div>
            <br />
            <div className="privacy-text">
              The Company may use Personal Data for the following purposes:
            </div>
            <br />
            <div className="privacy-text">
              <ul>
                <li>
                  To provide and maintain our Service , including to monitor the
                  usage of our Service.
                </li>

                <li>
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </li>

                <li>
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </li>

                <li>
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>

                <li>
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </li>

                <li>
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
              </ul>
              <br />
              <div className="privacy-text">
                We may share Your personal information in the following
                situations:
              </div>
              <br />
              <div className="privacy-text">
                <ul>
                  <li>
                    With Service Providers: We may share Your personal
                    information with Service Providers to monitor and analyze
                    the use of our Service, for payment processing, to contact
                    You.
                  </li>
                  <li>
                    For business transfers: We may share or transfer Your
                    personal information in connection with, or during
                    negotiations of, any merger, sale of Company assets,
                    financing, or acquisition of all or a portion of Our
                    business to another company.
                  </li>
                  <li>
                    With Affiliates: We may share Your information with Our
                    affiliates, in which case we will require those affiliates
                    to honor this Privacy Policy. Affiliates include Our parent
                    company and any other subsidiaries, joint venture partners
                    or other companies that We control or that are under common
                    control with Us.
                  </li>
                  <li>
                    With business partners: We may share Your information with
                    Our business partners to offer You certain products,
                    services or promotions.
                  </li>
                  <li>
                    With other users: when You share personal information or
                    otherwise interact in the public areas with other users,
                    such information may be viewed by all users and may be
                    publicly distributed outside.
                  </li>
                  <li>
                    With Your consent : We may disclose Your personal
                    information for any other purpose with Your consent.
                  </li>
                </ul>
              </div>
              <br />
              <div className="privacy-title">
                Retention of Your Personal Data
              </div>
              <div className="privacy-text">-------------------------</div>
              <br />
              <div className="privacy-text">
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </div>
              <br />
              <div className="privacy-title">
                Transfer of Your Personal Data
              </div>
              <div className="privacy-text">-------------------------</div>
              <br />
              <div className="privacy-text">
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </div>
              <br />

              <div className="privacy-text">
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </div>
              <br />

              <div className="privacy-text">
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </div>
              <br />
              <div className="privacy-title">
                Disclosure of Your Personal Data
              </div>
              <div className="privacy-text">-------------------------</div>
              <br />
              <div className="privacy-title">Business transactions</div>
              <div className="privacy-text">~~~~~~~~~~~~~~~~~~~~~</div>
              <br />
              <div className="privacy-title">
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </div>
              <br />
              <div className="privacy-title">Other legal requirements </div>
              <div className="privacy-text">~~~~~~~~~~~~~~~~~~~~~~~~</div>
              <br />
              <div className="privacy-text">
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </div>
              <br />
              <div className="privacy-text">
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
              </div>
              <br />
              <div className="privacy-title">
                Security of Your Personal Data
              </div>
              <div className="privacy-text">------------------------------</div>
              <br />

              <div className="privacy-text">
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </div>
              <br />
              <div className="privacy-title">
                Detailed Information on the Processing of Your Personal Data
              </div>
              <div className="privacy-text">
                ============================================
              </div>
              <br />
              <div className="privacy-text">
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
              </div>
              <br />
              <div className="privacy-title">Payments</div>
              <div className="privacy-text">---------</div>
              <br />
              <div className="privacy-text">
                We may provide paid products and/or services within the Service.
                In that case, we may use third-party services for payment
                processing (e.g. payment processors).
              </div>
              <br />
              <div className="privacy-text">
                We will not store or collect Your payment card details. That
                information is provided directly to Our third-party payment
                processors whose use of Your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </div>
              <br />
              <div className="privacy-text">
                <ul>
                  <li>Stripe</li>
                </ul>
              </div>
              <br />
              <div className="privacy-text">
                Their Privacy Policy can be viewed at{" "}
                {`<https://stripe.com/us/privacy>`}
              </div>
              <br />
              <div className="privacy-title">Children's Privacy</div>
              <div className="privacy-text">=================</div>

              <br />
              <div className="privacy-text">
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </div>
              <br />
              <div className="privacy-text">
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent's consent before We collect
                and use that information.
              </div>
              <br />
              <div className="privacy-title">Links to Other Websites </div>
              <div className="privacy-text">=================</div>

              <br />
              <div className="privacy-text">
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </div>
              <br />
              <div className="privacy-text">
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </div>
              <br />
              <div className="privacy-text">
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent's consent before We collect
                and use that information.
              </div>
              <br />
              <div className="privacy-title">
                Changes to this Privacy Policy
              </div>
              <div className="privacy-text">=================</div>

              <br />
              <div className="privacy-text">
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </div>
              <br />
              <div className="privacy-text">
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy.
              </div>
              <br />
              <div className="privacy-text">
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </div>

              <div className="privacy-text">
                <br />
                <div className="privacy-title">Contact Us</div>
                <div className="privacy-text">==========</div>

                <br />
                <div className="privacy-text">
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </div>
                <br />
                <div className="privacy-text">
                  <ul>
                    <li>
                      By email:
                      <a hrerf="email:support@colibriautotransport.com">
                        support@colibriautotransport.com
                      </a>
                    </li>
                  </ul>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
