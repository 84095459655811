import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

import { Button } from "react-bootstrap";

import "./style.css";
//import "./radio.css";
import Desktop from "./desktop/MainScreen.desktop";
import Mobile from "./mobile/MainScreen.mobile";
const url = "https://api.securepaymentpcs.com";
//const url = "http://localhost:5555";
const options = {
  style: {
    base: {
      color: "white",
      fontSize: "16px",
      "::placeholder": {
        color: "white",
      },
    },
    invalid: {
      color: "#F37676",
    },
  },
};
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      cardNumber: "",
      cardName: "",
      loading: true,
      isError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const stripePromise = loadStripe(
      "pk_live_51N1GkcG2TI3vClsURgxXhSFrcChybzncQxz4OGnAnaV6vCW4IPr4G1sIMNa7xJcbCzMRILfSoIKNN0cw32YRXTXJ00CTWnoLAq"
    );
    this.setState({ stripePromise });

    const { id } = this.props.match.params;
    axios
      .post(`${url}/v1/stripe/get`, {
        id,
      })
      .then(({ data }) => {
        const { order, error, reason } = data;
        let isError = false;
        if (error) {
          isError = reason;
        }

        this.setState({ order, isError, loading: false });
      });
  }

  handleSubmit = async ({
    cardName,
    stripe,
    elements,
    address,
    zip,
    order,
  }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { orderId, searchOrderId } = order;

    this.setState({ isSubmitting: true });

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("cardNumber"),
      billing_details: {
        // Include any additional collected billing details.
        name: cardName,
      },
    });
    if (error) {
      alert(error.message);
      return null;
    } else if (paymentMethod) {
      axios
        .post(`${url}/v1/stripe/pay`, {
          payment_method_id: paymentMethod.id,
          orderId,
          searchOrderId,
          zip,
          address,
          name: order.name,
          email: order.email,
          phone: order.phoneNumber,
          description: orderId,
        })
        .then(({ data }) => {
          const success = data.success && true;
          this.setState({ isSubmitting: false, success });
          if (data.error) {
            alert("Something went wrong, contact admin");
          }
        });
    }
  };

  render() {
    let { stripePromise, isError, loading, order, isSubmitting, success } =
      this.state;

    if (loading)
      return (
        <div className="body info">
          <div className="content info-content">
            <div className="info-text">Searching for your order</div>
          </div>
        </div>
      );
    if (isError)
      return (
        <div className="body info">
          <div className="content info-content">
            <div className="info-text">{isError}</div>
          </div>
        </div>
      );
    if (success)
      return (
        <div className="body info">
          <div className="content success-content">
            <div className="title">Thank you for placing an order</div>
            <div className="subtitle">
              Your payment information has been successfully submitted. What’s
              next? Our agent will be assigning a carrier to your order and your
              vehicle will be on it’s way soon!
              <br />
              <br />
              If you still have any questions regarding your order feel free to
              reach out to your shipping agent or our Support Team or simply
              visit our website for frequently asked questions by following this
              link:
            </div>
            <a
              className="link"
              target="_blank"
              rel="noopener"
              href="https://www.colibriautotransport.com/"
            >
              colibriautotransport.com →
            </a>
          </div>
        </div>
      );
    return (
      <div className="body">
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) =>
              window.innerWidth > 900 ? (
                <Desktop
                  elements={elements}
                  stripe={stripe}
                  options={options}
                  order={order}
                  isSubmitting={isSubmitting}
                  submit={this.handleSubmit}
                />
              ) : (
                <Mobile
                  elements={elements}
                  stripe={stripe}
                  options={options}
                  order={order}
                  isSubmitting={isSubmitting}
                  submit={this.handleSubmit}
                />
              )
            }
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }
}

export default Home;
