import React from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Recaptcha from "react-google-invisible-recaptcha";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.mobile.css";

import BankOne from "../../assets/banks/1.svg";
import BankTwo from "../../assets/banks/2.svg";
import BankThree from "../../assets/banks/3.svg";
import BankFour from "../../assets/banks/4.svg";

import ColibriLogo from "../../assets/ColibriLogo.png";

import Arrow from "../../assets/bottom-arrow.svg";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      cardName: "",
      zipInput: "",
      addressInput: "",
      privacyAccepted: false,
      isSubmitting: false,
      radios: [
        {
          id: "pickupAddress",
          text: "Same as pick up address",
          checked: false,
        },
        {
          id: "deliveryAddress",
          text: "Same as delivery address",
          checked: false,
        },
        { id: "other", text: "Other", checked: true },
      ],
    };
    this.change = this.change.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.onCaptchaResolved = this.onCaptchaResolved.bind(this);
    this.submitReady = this.submitReady.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
    this.toggleOrders = this.toggleOrders.bind(this);
  }

  change(event) {
    let { state } = this;
    state[event.target.name] = event.target.value;

    this.setState(state);
    this.submitReady();
  }

  radioChange(e) {
    let { radios, addressInput, zipInput } = this.state;
    const { order } = this.props;

    radios.map((b) => {
      if (b.id == e.target.id) {
        b.checked = true;
      } else {
        b.checked = false;
      }
      return b;
    });

    switch (e.target.id) {
      case "pickupAddress":
        addressInput = order.pickupAddress;
        zipInput = order.pickupZip;
        break;
      case "deliveryAddress":
        addressInput = order.deliveryAddress;
        zipInput = order.deliveryZip;
        break;
      default:
        addressInput = "";
        zipInput = "";
        break;
    }

    this.setState({ radios, addressInput, zipInput }, () => this.submitReady());
  }
  onCaptchaResolved() {
    const { stripe, elements, order } = this.props;

    const { cardName, zipInput, addressInput } = this.state;

    this.props.submit({
      cardName,
      stripe,
      elements,
      zip: zipInput,
      order,
      address: addressInput,
    });
  }
  checkboxChange({ target }) {
    this.setState({ privacyAccepted: target.checked }, () => {
      this.submitReady();
    });
  }
  stripeElementChange = (element, name) => {
    if (!element.empty && element.complete) {
      this.setState({ [name]: true });
    } else {
      this.setState({ [name]: false });
    }
    this.submitReady();
  };

  toggleOrders() {
    const { ordersActive } = this.state;
    this.setState({ ordersActive: !ordersActive });
  }
  submitReady() {
    const {
      cardName,
      zipInput,
      cardCvv,
      cardNumber,
      cardExpiry,
      privacyAccepted,
    } = this.state;
    if (
      !cardName ||
      !zipInput ||
      !cardNumber ||
      !cardExpiry ||
      !cardCvv ||
      !privacyAccepted
    ) {
      this.setState({ submitReady: false });
      return null;
    }

    this.setState({ submitReady: true });
  }

  render() {
    const {
      cardName,
      addressInput,
      zipInput,
      radios,
      submitReady,
      privacyAccepted,
      ordersActive,
    } = this.state;
    const { options, isSubmitting, order } = this.props;
    let vehicles = [];
    try {
      vehicles = JSON.parse(order.vehicles);
    } catch (e) {
      console.error(e);
    }
    return (
      <div className="content">
        <img className="logo" src={ColibriLogo} />
        <div className="page-title">Payment information</div>
        <div className="content-container">
          <div className="card-container">
            <div className="top-card">
              <div className="card-title">
                <div className="card-quote">
                  <span className="quote-text">Quote:</span>
                  <span className="quote-price">{order.quote}$</span>
                </div>
                <div className="card-banks">
                  <img src={BankOne} />
                  <img src={BankTwo} />
                  <img src={BankThree} />
                  <img src={BankFour} />
                </div>
              </div>

              <div className="card-number simple-input">
                <span>Card number</span>
                <CardNumberElement
                  className="card-number-input card-input"
                  options={options}
                  name="cardNumber"
                  onChange={(element) =>
                    this.stripeElementChange(element, "cardNumber")
                  }
                />
              </div>
              <div className="double-group">
                <div className="card-holder simple-input">
                  <span>Card holder</span>

                  <input
                    type="text"
                    placeholder="John Doe"
                    onChange={this.change}
                    name={"cardName"}
                    value={cardName}
                  />
                </div>
                <div className="card-expiration date-input">
                  <span>Expiration date</span>
                  <CardExpiryElement
                    className="card-expiration-input card-input"
                    options={options}
                    onChange={(element) =>
                      this.stripeElementChange(element, "cardExpiry")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bottom-card">
              <div className="left-content">
                <div className="card-line"></div>
                <div className="card-cvv">
                  <span>CVV</span>
                  <CardCvcElement
                    className="cvv-input"
                    options={options}
                    onChange={(element) =>
                      this.stripeElementChange(element, "cardCvv")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li>No charges upfront</li>
            <li>Partial payment will be charged after pick up</li>
            <li>Balance to the driver</li>
          </ul>
          <div className="billing-container">
            <div className="title">Billing address</div>
            <div className="billing-inputs">
              <div className="form-floating address">
                <input
                  type="text"
                  className="form-control"
                  id="addressVal"
                  name="addressInput"
                  onChange={this.change}
                  placeholder="Address"
                  value={addressInput}
                />
                <label htmlFor="addressVal">Address (optional)</label>
              </div>
              <div className="form-floating zip">
                <input
                  type="text"
                  className="form-control"
                  id="zipValu"
                  placeholder="Zip*"
                  value={zipInput}
                  name="zipInput"
                  onChange={this.change}
                />
                <label htmlFor="zipValu">Zip*</label>
              </div>
            </div>
            <div className="radios">
              {radios.map((e) => (
                <div className="form-check" key={e.id}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="addressRadio"
                    id={e.id}
                    value={e.checked}
                    onChange={this.radioChange}
                    checked={e.checked}
                  />
                  <label className="form-check-label" htmlFor={e.id}>
                    {e.text}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={privacyAccepted}
                onChange={this.checkboxChange}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I confirm that I have read the
                <Link to="/privacy"> privacy policy</Link>
              </label>
            </div>
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={"6LejAGkcAAAAAGqpFTUR0Wr1CX7IuO_ue_XVXdPD"}
              onResolved={this.onCaptchaResolved}
            />
            <div
              onClick={this.toggleOrders}
              className={`order-container-heading ${ordersActive && "active"}`}
            >
              Order details
              <img src={Arrow} />
            </div>
            <div className={`orders-container ${ordersActive && "active"}`}>
              <div className="order-info">
                <div className="order-title">Order from</div>
                <div className="order-divider" />
                <div className="order-text">{order.name}</div>
                <div className="order-text">{order.email}</div>
                <div className="order-text">{order.phoneNumber}</div>
              </div>
              <div className="order-info">
                <div className="order-title">Pick Up</div>
                <div className="order-divider" />
                <div className="order-text">{order.pickupName}</div>
                <div className="order-text">{order.pickupPhone}</div>
                <div className="order-text">{order.pickupAddress}</div>
              </div>
              <div className="order-info">
                <div className="order-title">Delivery</div>
                <div className="order-divider" />
                <div className="order-text">{order.deliveryName}</div>
                <div className="order-text">{order.deliveryPhone}</div>
                <div className="order-text">{order.deliveryAddress}</div>
              </div>
              <div className="order-info">
                <div className="order-title">Vehicles</div>
                <div className="order-divider" />
                {vehicles.map(
                  ({ condition, make, model, year }, index) => {
                    console.log({
                      condition,
                      make,
                      model,
                      year,
                    });
                    return (
                      <div>
                        {index < vehicles.length && index > 0 ? (
                          <div className="dashed-divider" />
                        ) : (
                          ""
                        )}
                        <div className="order-text">{condition}</div>
                        <div className="order-text">{make}</div>
                        <div className="order-text">{model}</div>
                        <div className="order-text">{year}</div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="order-info">
                <div className="order-title">First Available Pickup Date</div>
                <div className="order-divider" />
                <div className="order-text">{order.transportationDate}</div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary submit-button"
              onClick={() => this.recaptcha.execute()}
              disabled={!submitReady || isSubmitting ? true : false}
            >
              {isSubmitting ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm button-spinner"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Connecting to Stripe</span>
                </>
              ) : (
                <span class="sr-only">Confirm</span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
